import { Context } from "@nuxt/types";
import cookieNames from '~/enums/cookieNameEnum';
import { hasAuthorizationError } from "~/helpers/util";
import cookieNameEnum from '~/enums/cookieNameEnum';
import c from 'cookie';
import { Route } from 'vue-router';
// import localeMapEnum from '~/enums/localeMapEnum';
import { magentoStoreviewCodesMapping } from '~/locales';
// import { fixInvalidPageQueryParam } from "~/helpers/routeFixer";

const checkLocaleCookies = (allCookies: object, itOrEn: string): boolean => {
  return (
    !allCookies[cookieNameEnum.localeCookieName]
    || !allCookies[cookieNameEnum.storeCookieName]
    // || !allCookies[cookieNameEnum.languageCookieName]
    || allCookies[cookieNameEnum.localeCookieName] !== itOrEn
    || allCookies[cookieNameEnum.storeCookieName] !== itOrEn
    // || allCookies[cookieNameEnum.languageCookieName] !== localeMapEnum[itOrEn]
  );
};

const checkMarketCookies = (allCookies: object, market: string, currency: string): boolean => {
  return (
    !allCookies[cookieNameEnum.marketCookieName]
    || !allCookies[cookieNameEnum.currencyCookieName]
    || allCookies[cookieNameEnum.marketCookieName] !== market
    || allCookies[cookieNameEnum.currencyCookieName] !== currency
  );
};

function getMagentoUrlToRedirect(route: Route): Object {
  let localeFound = false;
  let magentoStoreViewCodeFrom = 'it';
  let geminiCodeToRedirect = 'it';
  for (const element in magentoStoreviewCodesMapping) {
    if (route.fullPath && route.fullPath.includes(`/${magentoStoreviewCodesMapping[element].magentoStoreViewCode}`)) {
      localeFound = true;
      magentoStoreViewCodeFrom = magentoStoreviewCodesMapping[element].magentoStoreViewCode;
      geminiCodeToRedirect = magentoStoreviewCodesMapping[element].geminiCode;
      break;
    }
  }
  return { localeFound, magentoStoreViewCodeFrom, geminiCodeToRedirect };
}

const hasInvalidPageQuery = (route: Route, pageSize: number | string): boolean => {
  if (!route.query?.page) {
    return false;
  }
  const pageParam = route.query.page as string;
  // Check if the page query is a number
  const isNumeric = /^\d+$/.test(pageParam);
  // If pageParam is not a number, set page to NaN for automatic fail on this check
  const page = isNumeric ? Number.parseInt(pageParam as string, 10) : NaN;
  // Elastic search has a limit of 10,000 results for products
  const maxPossiblePage = Math.floor(10_000 / (Number.parseInt(pageSize as string, 10) || 24));
  return Number.isNaN(page) || page < 1 || page > maxPossiblePage;
}

export default async ({ app, route, redirect, next, req, $vsf, query, error: nuxtError }: Context) => {
  if (!!app.$cookies.get(cookieNames.languageCookieName)) {
    app.$cookies.remove(cookieNames.languageCookieName);
  }

  const needsToBeRedirected = getMagentoUrlToRedirect(route);
  if (needsToBeRedirected['localeFound']) {
    redirect(301, `${route.fullPath.replace(needsToBeRedirected['magentoStoreViewCodeFrom'], needsToBeRedirected['geminiCodeToRedirect'])}`);
    return;
  }

  const hpPattern = /^\/([a-zA-Z]{2,3})$/;
  const hpPatternWithSlash = /^\/([a-zA-Z]{2,3})\/$/;
  const hpMatch = route.path.match(hpPattern);
  const hpWithSlashMatch = route.path.match(hpPatternWithSlash);
  if (hpMatch) {
    redirect(301, `${route.fullPath.replace(route.path, `${route.path}/`)}`);
    return;
  } else if (route.path.length > 1 && !hpWithSlashMatch && route.path.at(-1) === '/') {
    redirect(301, `${route.fullPath.replace(route.path, route.path.slice(0, -1))}`);
    return;
  }

  if (hasAuthorizationError(app.$cookies.get(cookieNames.customerCookieName))) {
    app.$cookies.remove(cookieNames.customerCookieName);
    app.$cookies.remove(cookieNames.cartCookieName);
    app.$cookies.remove(cookieNames.segmentsCookieName);
    app.$cookies.set(cookieNames.messageCookieName, {
      message: app.i18n.t('You are not authorized, please log in.'),
      type: 'warning',
      icon: null,
      persist: false,
      title: null,
    });

    if (!process.client) {
      redirect(302, app.$fixUrlForCurrentLocale('/'));
    } else {
      next(false);
      window.location.href = app.$fixUrlForCurrentLocale('/');
    }
  }

  // @ts-ignore
  const { categoryPageSize } = $vsf.$gemini.config;
  if (hasInvalidPageQuery(route, categoryPageSize)) {
    console.warn(`Invalid page query: ${route.query.page}`);
    // redirect(301, fixInvalidPageQueryParam(route));
    nuxtError({ statusCode: 404 });
    return;
  }

  if(req){
    // @ts-ignore
    const { market, useCookieMarket } = req.query;
    if (market) {
      // @ts-ignore
      const localesToMarketsFromConfig = $vsf?.$gemini?.config?.localesToMarkets;
      if (localesToMarketsFromConfig) {
        const locale = Object.keys(localesToMarketsFromConfig).find((key) => localesToMarketsFromConfig[key].includes(market));
        const splitRoute = route.fullPath.split('/');
        splitRoute[1] = locale;
        const newRoute = splitRoute.join('/');
        let noParamRoute = newRoute.split('?')[0]
        if (Object.keys(route.query).includes('token')){
          noParamRoute = noParamRoute + '?token=' + route.query.token;
        }
        redirect(302, noParamRoute);
        return;
      }
    } else if (useCookieMarket) {
      // @ts-ignore
      const cookiesObject = c.parse(req?.headers?.cookie);
      // @ts-ignore
      const localesToMarketsFromConfig = $vsf?.$gemini?.config?.localesToMarkets;
      const cookieMarket = cookiesObject['vsf-market'];
      const locale = Object.keys(localesToMarketsFromConfig).find((key) => localesToMarketsFromConfig[key].includes(cookieMarket));
      const splitRoute = route.fullPath.split('/');
      splitRoute[1] = locale;
      const newRoute = splitRoute.join('/');
      let noParamRoute = newRoute.split('?')[0];
      if (Object.keys(route.query).includes('token')) {
        noParamRoute = noParamRoute + '?token=' + route.query.token;
      }
      redirect(302, noParamRoute);
      return;
    }
  }

  const allCookies = app.$cookies.getAll();
  const localeArray = route.fullPath ? route.fullPath.split('/') : null;
  let cleanLocaleFromUrl = '';
  if (Array.isArray(localeArray) && localeArray.length > 0) {
    cleanLocaleFromUrl = localeArray[1];
  }
  if (allCookies && cleanLocaleFromUrl && cleanLocaleFromUrl !== '') {
    const itOrEn = cleanLocaleFromUrl === 'it' ? 'it' : 'en';
    const shouldChangeLocaleCookies = checkLocaleCookies(allCookies, itOrEn);
    // @ts-ignore
    const localesToMarketsFromConfig = $vsf?.$gemini?.config?.localesToMarkets;
    if (localesToMarketsFromConfig && localesToMarketsFromConfig[cleanLocaleFromUrl]) {
      const [market, currency] = localesToMarketsFromConfig[cleanLocaleFromUrl].split('|') ?? [null, null];
      const shouldChangeMarketAndCurrencyCookies = checkMarketCookies(allCookies, market, currency);
      if (shouldChangeLocaleCookies || shouldChangeMarketAndCurrencyCookies) {
        app.$cookies.set(cookieNameEnum.localeCookieName, itOrEn);
        app.$cookies.set(cookieNameEnum.storeCookieName, itOrEn);
        app.$cookies.set(cookieNameEnum.countryCookieName, itOrEn);
        app.$cookies.set(cookieNameEnum.marketCookieName, market);
        app.$cookies.set(cookieNameEnum.currencyCookieName, currency);
        // app.$cookies.set(cookieNameEnum.languageCookieName, localeMapEnum[itOrEn]);
        if (req?.headers?.cookie) {
          const cookiesObject = c.parse(req?.headers?.cookie);
          cookiesObject['vsf-locale'] = itOrEn;
          cookiesObject['vsf-store'] = itOrEn;
          cookiesObject['vsf-country'] = itOrEn;
          cookiesObject['vsf-market'] = market;
          cookiesObject['vsf-currency'] = currency;
          // cookiesObject['vsf-accept-language'] = localeMapEnum[itOrEn];
          let newCookieString = '';
          Object.keys(cookiesObject).forEach((key) => {
            newCookieString += `${key}=${cookiesObject[key]}; `;
          });
          req.headers.cookie = newCookieString.trim().slice(0, -1);
        }
      }
    } else {
      const itOrEn = cleanLocaleFromUrl === 'it' ? 'it' : 'en';
      const currency = app?.$i18n?.locales?.find(c => c.code === cleanLocaleFromUrl).defaultCurrency;
      app.$cookies.set(cookieNameEnum.localeCookieName, itOrEn);
      app.$cookies.set(cookieNameEnum.storeCookieName, itOrEn);
      app.$cookies.set(cookieNameEnum.countryCookieName, itOrEn);
      app.$cookies.set(cookieNameEnum.currencyCookieName, currency);
      // app.$cookies.set(cookieNameEnum.languageCookieName, localeMapEnum[itOrEn]);
      if (req?.headers?.cookie) {
        const cookiesObject = c.parse(req?.headers?.cookie);
        cookiesObject['vsf-locale'] = itOrEn;
        cookiesObject['vsf-store'] = itOrEn;
        cookiesObject['vsf-country'] = itOrEn;
        // cookiesObject['vsf-accept-language'] = localeMapEnum[itOrEn];
        if (currency) cookiesObject['vsf-currency'] = currency;
        let newCookieString = '';
        Object.keys(cookiesObject).forEach((key) => {
          newCookieString += `${key}=${cookiesObject[key]}; `;
        });
        req.headers.cookie = newCookieString.trim().slice(0, -1);
      }
    }
  }
};
